
import { defineComponent } from 'vue';
import Accordian from '@/components/clickables/Accordian.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import MedicineService from '@/services/MedicineService';
import {
  Product,
  ProductData,
  ProductPackage,
} from '@/models/product';
import PriceFormatter from '@/mixins/PriceFormatter';
import moment from 'moment';

export default defineComponent({
  name: 'ProductNew',
  mixins: [PriceFormatter],
  components: {
    Accordian,
    Pagination,
    UiSearch,
    PreLoader,
  },
  data: () => ({
    size: 1 as number,
    total: 0 as number,
    currentPage: 1 as number,
    productList: [] as ProductData[],
    lastUpdated: '' as string,
    productName: '' as string,
    isLoaded: false,
    isEmpty: false,
    products: [] as Product[],
    searchTimeoutId: 0,
    isPageLoaded: false,
    packingTableHeader: [
      'Størrelse',
      'NVR',
      'Forbrugerpris inkl. moms',
      'Udleveringsbestemmelse',
    ],
    notFound: [] as boolean[],
  }),
  created(): void {
    this.getProducts();
  },
  computed: {
    currentItems(): number {
      const currentTotal = 10 * this.currentPage;
      return currentTotal >= this.total ? this.total : currentTotal;
    },
  },
  watch: {
    async productName(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        this.isEmpty = false;
        this.currentPage = 1;
        this.getProducts();
      }, 300);
    },
  },
  async mounted() {
    const response = await MedicineService.lastSyncedAt();

    this.lastUpdated = moment(response?.last_synced_at).format('DD.MM.YY');
  },
  methods: {
    getProductCaption(model?: any) {
      const strength = model?.medicine_details?.strength?.text;

      return `${model?.medicine_details?.dosage_form?.text} ${strength ? `(${strength})` : ''}`;
    },
    scrollBack(): void {
      const source = this.$refs['product-new-anchor'] as HTMLElement;
      const top = source.offsetTop - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    },
    async getProducts(): Promise<void> {
      this.isLoaded = false;
      this.productList = [] as ProductData[];
      this.notFound = [] as boolean[];
      this.total = 0;

      const service = await MedicineService.listProducts(
        'pendingnew',
        this.productName,
        this.currentPage,
      );
      if (service.data.length > 0) {
        this.productList = service.data;
        this.size = Math.ceil(service.total / service.per_page);
        this.total = service.total;
        this.isEmpty = false;
        this.productList.forEach(() => this.notFound.push(false));
      } else {
        this.isEmpty = true;
      }

      this.isLoaded = true;
      this.isPageLoaded = true;
    },
    async isAccordianOpened(id: string, index: number): Promise<void> {
      if (!this.getProduct(id)) {
        try {
          const product = await MedicineService.getProduct(id);
          product.id = id;
          this.products.push(product);
          this.notFound[index] = false;
        } catch (error) {
          this.notFound[index] = true;
        }
      }
    },
    getProduct(id: string): Product {
      const product = this.products.filter((prod) => prod.id === id)[0];
      return product;
    },
    checkIfRegDateSet(product: Product): boolean {
      const date = product.medicine_details.de_registration_date;
      return date !== '' && date !== null;
    },
    checkIfWithDateSet(pack: ProductPackage): boolean {
      const date = pack.package_details.withdrawal_date;
      return date !== '' && date !== null;
    },
    formatDate(date: number): string {
      const newDate = moment(date).format('DD[.]MM[.]YY');

      return newDate;
    },
    onHoverMessage(date: number): string {
      return `Udgået ${
        this.formatDate(date)
      }. Lægemidlet kan være helt udgået eller midlertidigt udgået pga. leveringssvigt.`;
    },
    paginationHandler(): void {
      this.getProducts();
    },
    createProduct(id: string): void {
      this.$router.push({
        name: 'ProductCreate',
        params: {
          drugId: id,
        },
      });
    },
  },
});
