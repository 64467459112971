import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bd3d6d04"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["visibility"]
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "caption"
}
const _hoisted_4 = { class: "accordian-body-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "accordian",
    visibility: _ctx.show ? 'show' : 'hide'
  }, [
    _createElementVNode("div", {
      class: "accordian-header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("div", {
        class: "accordian-title",
        title: _ctx.title
      }, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        (_ctx.caption)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.caption), 1))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2),
      _createVNode(_component_font_awesome_icon, {
        class: "accordian-icon",
        icon: ['fal', 'angle-up']
      })
    ]),
    _createVNode(_Transition, {
      onBeforeEnter: _ctx.leave,
      onEnter: _ctx.enter,
      onBeforeLeave: _ctx.resize,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "accordian-body",
          onResize: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args)))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ], 544), [
          [_vShow, _ctx.show]
        ])
      ]),
      _: 3
    }, 8, ["onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave"])
  ], 8, _hoisted_1))
}