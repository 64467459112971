
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Accordian',
  props: {
    title: {
      type: String,
      default: 'Title',
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get(): any {
        return this.expanded;
      },
      set(val: any) {
        this.$emit('update:expanded', val);
      },
    },
  },
  methods: {
    toggle() {
      if (!this.show) {
        this.$emit('accordian:opened');
      }
      this.show = !this.show;
    },
    enter(target: HTMLElement) {
      const element = target;
      element.style.height = `${element.scrollHeight}px`;
      setTimeout(() => {
        element.style.height = 'auto';
      }, 150);
    },
    leave(target: HTMLElement) {
      const element = target;
      element.style.height = '0';
    },
    resize(target: HTMLElement) {
      const element = target;
      element.style.height = `${element.scrollHeight}px`;
    },
  },
});
