import {
  ProductResponse,
  Product,
  ProductData,
} from '@/models/product';
import http from '../utils/http-client';

const getLastUpdated = (items: Array<ProductData>): string => {
  if (items.length > 0) {
    const sortedData: ProductData = items.sort(
      (a, b) => (+new Date(b.updated_at) - +new Date(a.updated_at)),
    )[0];

    const date = new Date(sortedData.updated_at).toLocaleDateString();

    return date;
  }

  return '--/--/----';
};

class MedicineService {
  static async pending(): Promise<any> {
    const medicines = await http.get('/api/medicines/pendingnew');

    return medicines.data.data;
  }

  static async pendingByCompanyNew(companyId: string): Promise<any> {
    const medicines = await http.get(`/api/medicines/company/${companyId}/pendingnew`);

    return medicines.data.data;
  }

  static async pendingByCompanyUpdate(companyId: string): Promise<any> {
    const medicines = await http.get(`/api/medicines/company/${companyId}/pendingupdate`);

    return medicines.data.data;
  }

  static async approveProduct(idCode: string): Promise<any> {
    const isUpdated = await http.post(`/api/medicines/${idCode}/migrated`);

    return isUpdated;
  }

  static async getProduct(productId: string): Promise<Product> {
    const product: Product = await http.get(`/api/medicines/show/${productId}`);

    return product;
  }

  static async listProducts(
    type_action: string,
    name?: string,
    page?: number,
    company?: string,
  ): Promise<ProductResponse> {
    const {
      data,
      total,
      per_page: perPage,
      current_page: currentPage,
    }: ProductResponse = await http.get(
      `/api/medicines/${type_action}/?&name=${name}&page=${page}&company=${company}`,
    );

    const lastUpdated = getLastUpdated(data);

    return {
      data,
      total,
      lastUpdated,
      per_page: perPage,
      current_page: currentPage,
    };
  }

  static async showStatus(idCode: string): Promise<any> {
    const response = await http.get(`/api/medicines/showstatus/${idCode}`);

    return response;
  }

  static async lastSyncedAt(): Promise<any> {
    const response = await http.get('/api/medicines/last_synced_at');

    return response;
  }
}

export default MedicineService;
