export default {
  methods: {
    formatPrice(ammount: number | string) {
      if (!ammount || ammount === '0') {
        return 'Pris ikke oplyst';
      }

      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'DKK',
      }).format(Number(ammount) / 100);
    },
  },
};
